import Pristine from 'pristinejs';
import axios from 'axios';

export default function () {
  const form = document.getElementById('contact-form');
  const submitButton = document.getElementById('submit');
  const pristine = new Pristine(form, {
    errorTextClass: 'error',
    errorTextTag: 'label'
  });
  const contactResult = document.getElementById('contact-result');

  form.addEventListener('submit', async (e) => {
    e.preventDefault();

    if (pristine.validate()) {
      contactResult.innerHTML = 'Sending...';
      submitButton.disabled = true;
      const data = {
        name: form.name.value,
        email: form.email.value,
        subject: form.subject.value,
        msg: form.message.value,
        phone: form.phone.value
      };
      try {
        const result = await axios.post('https://api.wilderminds.com/api/messenger?template=WilderMindsContact.txt',
          data);
        if (result.data) {
          form.reset();
          contactResult.innerHTML = "Message Sent. We'll be in contact soon";
        } else {
          contactResult.innerHTML =
            'Server failed to send message. Message logged. Please try again later.';
        }
      } catch (e) {
        contactResult.innerHTML =
          'Technical issue sending email, message not sent. Please try again later.';
      } finally {
        submitButton.disabled = false;
      }
    }
  });
}
